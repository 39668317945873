import io from "socket.io-client";
import { Button, Input } from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import { SendOutlined, CloseOutlined } from "@ant-design/icons";

import { post } from "../../utils/fetch";
import { actionTypes } from "../../actions";
import CodeBlock from "../../components/CodeBlock";
import Markdown from "../../components/Markdown";
import TooltipButton from "../../components/TooltipButton";
import { PageContext, usePageActionHandler } from "../../components/Page";

import "./chat.css";

const { TextArea } = Input;

const intro = `Welcome! I'll help you define the document processing requirements for your project. Let's get started.

What type of document will you be uploading?`;
const chatInputPlaceholder = ``;

const Home = ({ project, getProject, back }) => {
  const { addAction, removeAction } = usePageActionHandler();
  const { setError } = useContext(PageContext);

  const chatRef = useRef();
  const chatEndRef = useRef();
  const [currentChatbotResponse, setCurrentChatbotResponse] = useState(null);
  const [chatItems, setChatItems] = useState(JSON.parse(localStorage.getItem("chatExtractionSchema") || "[]"));
  const [chatInputKey, setChatInputKey] = useState(Date.now());
  const [userReply, setUserReply] = useState(null);
  const [streaming, setStreaming] = useState(false);

  useEffect(() => {
    scrollToBottom();
  }, []);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKETIO);
    scrollToBottom();

    if (project.id) {
      localStorage.setItem("chatExtractionSchema", JSON.stringify(project.chat_extraction_schema));
      setChatItems(project.chat_extraction_schema);
      socket.emit("subscribeToStatusUpdate", project.id);
      socket.on("statusUpdate", (socketData) => {
        const { data } = socketData;
        if (data.error) {
          console.log('data.error', data.error);
          return;
        }

        if (data.action === "streamingChatExtractionSchemaResponse") {
          setCurrentChatbotResponse(data.data.reply);
          scrollToBottom();
        }

        if (data.action === "completeChatExtractionSchemaResponse") {
          removeAction("chatExtractionSchema")
          setChatItems(data.data.messages);
          setStreaming(false);
          setUserReply(null);
          setChatInputKey(Date.now())
          scrollToBottom();
        }
      });
    }

    return () => {
      socket.disconnect();
    };
  }, []);

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView();
  };

  const replyToChatbot = async () => {
    const action = "chatExtractionSchema";
    setUserReply(chatRef.current?.resizableTextArea?.textArea?.value);
    setStreaming(true);
    addAction(action)
    return await post(actionTypes[action].api, {
      projectId: project.id,
      userReply: chatRef.current?.resizableTextArea?.textArea?.value
    })
      .then(() => {
        scrollToBottom();
        setCurrentChatbotResponse(null);
      })
      .catch((err) => {
        setError(err);
      })
  }

  const endSession = async () => {
    const action = "extractDocExtractionSchema";
    addAction(action)
    return await post(actionTypes[action].api, {
      projectId: project.id,
    })
      .then(async () => {
        getProject('files')
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        removeAction(action)
      })
  }

  return (
    <div
      style={{
        maxWidth: "1080px",
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        padding: 10,
      }}
    >
      <div>
        <div
          className="overview-chat"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            overflow: "auto",
            height: "calc(-266px + 100vh)",
          }}
        >
          <div
            style={{
              backgroundColor: "antiquewhite",
              padding: "30px 30px 16px 30px",
              width: "80%",
              borderRadius: 10,
              marginLeft: 10,
              marginRight: "auto",
            }}
          >
            <Markdown
              className="chat-markdown"
              components={{ code: CodeBlock }}
            >
              {intro}
            </Markdown>
          </div>
          {chatItems?.map((item) => {
            return (
              <div
                key={item.id}
                style={{
                  backgroundColor:
                    item.role === "user" ? "#d0ebff" : "antiquewhite",
                  padding: "30px 30px 16px 30px",
                  width: "80%",
                  marginLeft: item.role === "user" ? "auto" : 10,
                  marginRight: item.role === "user" ? 10 : "auto",
                  borderRadius: 10,
                }}
              >
                <Markdown
                  className="chat-markdown"
                  components={{ code: CodeBlock }}
                >
                  {item.content}
                </Markdown>
              </div>
            );
          })}
          {streaming && userReply && (
            <div
              style={{
                backgroundColor: "#d0ebff",
                padding: "30px 30px 16px 30px",
                width: "80%",
                borderRadius: 10,
                marginLeft: "auto",
                marginRight: 10,
              }}
            >
              <Markdown
                className="chat-markdown"
                components={{ code: CodeBlock }}
              >
                {userReply}
              </Markdown>
            </div>
          )}
          {streaming && currentChatbotResponse && (
            <div
              style={{
                backgroundColor: "antiquewhite",
                padding: "30px 30px 16px 30px",
                width: "80%",
                borderRadius: 10,
                marginLeft: 10,
                marginRight: "auto",
              }}
            >
              <Markdown
                className="chat-markdown"
                components={{ code: CodeBlock }}
              >
                {currentChatbotResponse}
              </Markdown>
            </div>
          )}
          <div ref={chatEndRef} />
        </div>
        <div style={{ position: " relative", marginTop: 30 }}>
          <TextArea
            ref={chatRef}
            key={chatInputKey}
            rows={4}
            placeholder={chatInputPlaceholder}
            style={{ padding: '20px 10px 10px 10px' }}
            maxLength={1000}
            showCount
          />
          <div
            style={{
              position: "absolute",
              zIndex: 99,
              right: 16,
              top: -16,
              display: "flex",
              gap: 5,
              alignItems: "center",
            }}
          >
            <TooltipButton
              title="Submit"
              style={{ backgroundColor: "white", opacity: 1 }}
              type="default"
              size="medium"
              onClick={() => { replyToChatbot() }}
              icon={SendOutlined}
            />
            <Button
              onClick={async () => { endSession() }}
              style={{
                backgroundColor: "white",
                opacity: 1,
              }}
            >
              End session
            </Button>
            <Button
              onClick={async () => { back() }}
              style={{
                backgroundColor: "white",
                opacity: 1,
              }}
              icon={<CloseOutlined />}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
