/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { Button, Tabs, Tooltip } from 'antd';
import { useParams } from 'react-router-dom';
import { SyncOutlined } from "@ant-design/icons";

import "./project.css";

import useScrollToTop from '../../utils/useScrollToTop';
import { post } from '../../utils/fetch';
import { PageContext, usePageActionHandler } from '../../components/Page';
import { actionTypes } from '../../actions';
import Document from "./Document";

import ExcelConfig from './ExcelConfig';
import Show from '../../components/Show';
import MainMenu from '../../components/MainMenu';
import Download from './Download';
import { truncateString } from '../../utils/utils';
import CustomTag from '../../components/CustomTag';
import Logo from '../../components/Logo';

export const ProjectContext = createContext(null);

const Project = () => {
  useScrollToTop()
  const { setError, isActionInProgress } = useContext(PageContext);
  const { addAction, removeAction } = usePageActionHandler();
  const { folderId } = useParams();
  const [project, setProject] = useState(null);
  const [activeTabKey, setActiveTabKey] = useState("schema")

  useEffect(() => {
    getProject();
  }, [])

  const getProject = async (tabKey) => {
    const action = "getProject";
    addAction(action);
    return await post(actionTypes[action].api, {
      projectId: folderId
    })
      .then((res) => {
        setProject(res.data.project)
        if (tabKey) {
          setActiveTabKey(tabKey)
        }
        return res.data.project
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        removeAction(action);
      })
  }

  const downloadExcel = async () => {
    const action = "downloadExcel";
    addAction(action);
    return await post(actionTypes[action].api, {
      projectId: folderId
    })
      .then((res) => {
        window.open(res.data.downloadUrl)
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        removeAction(action);
      })
  }

  return (
    <ProjectContext.Provider>
      <div className='project-root'>
        <Tabs
          style={{ height: 'calc(100vh - 12px)' }}
          activeKey={activeTabKey}
          destroyInactiveTabPane
          tabPosition="top"
          type="card"
          onChange={(key) => { setActiveTabKey(key) }}
          tabBarExtraContent={{
            right: <MainMenu />,
            left: project?.id && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {window.innerWidth > 500 && <Logo color="black" width={23} height={23} />}
                <div style={{ display: 'flex', gap: 5, alignItems: 'center', margin: '0 20px' }}>
                  <CustomTag style={{ height: 24 }}>
                    <Tooltip title={project?.name}>
                      {truncateString(project?.name, window.innerWidth > 700 ? 50 : 20)}
                    </Tooltip>
                  </CustomTag>
                  <Button
                    size='small'
                    icon={<SyncOutlined />}
                    onClick={async () => {
                      if (activeTabKey === 'schema') {
                        const updatedProject = {...project};
                        updatedProject.excel_columns = null;
                        setProject(updatedProject)
                      }
                      if (activeTabKey !== 'schema') {
                        const updatedProject = {...project};
                        updatedProject.project_files = null;
                        setProject(updatedProject)
                      }
                      await getProject();
                    }}
                  />
                </div>
              </div>
            )
          }}
          items={[
            {
              label: "Schema",
              key: "schema",
              children: activeTabKey === 'schema' && (
                <Show show={!isActionInProgress('getProject') && project?.excel_columns}>
                  <ExcelConfig project={project} getProject={getProject} setProject={setProject} />
                </Show>
              )
            },
            {
              label: "Documents",
              disabled: !project || project?.excel_columns?.length === 0,
              key: "files",
              children: activeTabKey === 'files' && (
                <Show show={project?.project_files}>
                  <Document project={project} getProject={getProject} setProject={setProject} />
                </Show>
              )
            },
            {
              label: "Download",
              disabled: !project || project?.excel_columns?.length === 0,
              key: "download",
              children: activeTabKey === 'download' && (
                <Show show={!isActionInProgress('getProject') && project?.project_files}>
                  <Download
                    downloadExcel={downloadExcel}
                    project={project}
                    lastUpdated={project?.project_files?.find((item) => item.fileStatus === '12')?.updated_date}
                  />
                </Show>
              )
            },
          ]}
        />
      </div>
    </ProjectContext.Provider>
  );
}

export default Project;
