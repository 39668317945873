import axios from "axios";
import { getAuth } from "firebase/auth";
import firebaseApp from "../firebase";

import { isValidUrl } from './utils';

async function post(url, body={}) {
  const apiEndpoint = isValidUrl(url)
    ? url 
    : `${process.env.REACT_APP_API_ENDPOINT}${url}`;

  return axios.post(apiEndpoint, {
    ...body,
    idToken: localStorage.getItem("accessToken"),
  })
    .then((response) => {
      return response.data
    })
    .catch(async (err) => {
      console.log('err?.response?.data', err?.response?.data?.error);
      
      if (
        err?.response?.data?.error?.details?.code === 'auth/id-token-expired'
        || err?.response?.data?.error?.details?.code === 'auth/argument-error'
      ) {
        const auth = getAuth(firebaseApp);
        if (auth.currentUser) {
          const token = await auth.currentUser.getIdToken(true);
          localStorage.setItem('accessToken', token);
          return await post(url, body);
        } else {
          localStorage.removeItem('accessToken')
          window.location.href = `${window.location.protocol}//${window.location.host}/signin`;
        }
      }
      // if (err.response.data.error.public && err?.response?.data?.error?.message) {
      //   return { error: err?.response?.data?.error?.message }
      // }
      if (err?.response?.data?.status === 429) {
        return { error: err?.response?.data?.error.message }
      }
      // console.log({ err }, err?.response?.data?.status);
      throw err;
    })
}

export {
  post
}