const adminApis = {
  development: "",
  production: process.env.REACT_APP_ADMIN_API
}

const adminApi = adminApis[process.env.REACT_APP_ENV];

const actionTypes = {
  extractDocExtractionSchema: {
    api: `/extract-doc-extraction-schema`
  },
  chatExtractionSchema: {
    api: `/chat-extraction-schema`
  },
  updateCourseCover: {
    api: `/update-course-cover`
  },
  redoConversion: {
    api: `/redo-conversion`
  },
  updateExtractionSetting: {
    api: `/update-extraction-setting`
  },
  downloadExcel: {
    api: `/download-excel`
  },
  getFile: {
    api: `/get-file`
  },
  deleteFile: {
    api: `/delete-file`
  },
  setFileStatus: {
    api: `/set-file-status`
  },
  convertFileToJson: {
    api: `/convert-file-to-json`
  },
  convertJsonToExcel: {
    api: `/convert-json-to-excel`
  },
  updateExcelColumns: {
    api: `/update-excel-columns`,
    confirmationText: "Changing schema require you to redo the files conversion."
  },
  getCoverTemplates: {
    api: `/get-cover-templates`
  },
  createUploadImgPresigned: {
    api: `/create-upload-img-presigned`
  },
  getPrompts: {
    api: `${adminApi}/v3-get-prompts`
  },
  toggleHandsOn: {
    api: '/toggle-hands-on'
  },
  updateCourseVisibility: {
    api: '/update-course-visibility'
  },
  createUser: {
    api: '/create-user'
  },
  getCourse: {
    api: '/get-course'
  },
  getCourseDetails: {
    api: '/get-course-details'
  },
  getSubmoduleContent: {
    api: "/get-keypoint-content"
  },
  getUser: {
    api: '/get-user'
  },
  createProject: {
    api: "/create-project",
  },
  initializeCourse: {
    api: "/initialize-course"
  },
  getProjects: {
    api: '/get-projects'
  },
  getProject: {
    api: '/get-project'
  },
  deleteCourse: {
    api: '/delete-course',
  },
  addNewCourseModule: {
    api: "/add-course-module"
  },
  sortCourseModules: {
    api: "/sort-modules"
  },
  addNewCourseSubmodule: {
    api: "/add-course-submodule"
  },
  sortCourseSubmodules: {
    api: "/sort-submodules"
  },
  updateCourseModule: {
    api: "/update-course-module"
  },
  deleteProject: {
    api: "/delete-project",
    confirmationText: "Are you sure you want to delete this folder?"
  },
  getUserActivity: {
    api: `/get-user-activity`
  },
  clearAiTutorSession: {
    api: "/clear-session"
  },
  updateCourseOverview: {
    api: "/update-course-overview"
  },
  updateSubmoduleContent: {
    api: "/update-keypoint-content"
  },
  updateSubmoduleBasicDetails: {
    api: "/update-submodule-basic-details"
  },
  createTokenPaymentLink: {
    api: "/create-token-payment-link"
  },
  downloadCoursePdf: {
    api: `/trigger-download-course-pdf`
  },
}

export {
  actionTypes
};
