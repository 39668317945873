import React, { useContext, useRef, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Button, Card, Form, Input, Tooltip, Checkbox } from 'antd';
import { SettingOutlined, LeftOutlined, RightOutlined, CommentOutlined } from '@ant-design/icons';

import "./project.css";
import useScrollToTop from '../../utils/useScrollToTop';
import { post } from '../../utils/fetch';
import { PageContext, usePageActionHandler } from "../../components/Page";
import { actionTypes } from '../../actions';
import Chatbot from './Chatbot';

const Preference = ({ setSchemaGenerationPreference }) => {
  return (
    <div style={{ minHeight: 'calc(100vh - 200px)', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <h2 style={{ textAlign: 'center', marginBottom: 40 }}>Choose how to define your extraction schema</h2>
      <div style={{ display: 'flex', gap: 12, justifyContent: 'center', width: '100%' }}>
        <Card hoverable onClick={() => { setSchemaGenerationPreference('chatbot-assisted') }} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', maxWidth: 400, margin: 0, height: 200 }}>
          <div>
            <h2 style={{ textAlign: 'center' }}>Chatbot Assisted</h2>
          </div>
        </Card>
        <Card hoverable onClick={() => { setSchemaGenerationPreference('manual') }} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', maxWidth: 400, margin: 0, height: 200 }}>
          <div>
            <h2 style={{ textAlign: 'center' }}>Manual</h2>
          </div>
        </Card>
      </div>
    </div>
  )
}

const { TextArea } = Input;

const ExcelConfig = (props) => {
  useScrollToTop()
  const { setError, withConfirmation } = useContext(PageContext);
  const { addAction, removeAction } = usePageActionHandler();
  const { folderId } = useParams();
  const [form] = Form.useForm();
  const goalRef = useRef(null);
  const [showGoal, setShowGoal] = useState(true);
  const [project, setProject] = useState(props.project)
  const [showCustomInstruction, setShowCustomInstruction] = useState(
    props.project?.goals
    || !!(project.excel_columns.find((item) => item.instruction?.length))
  );
  const [schemaGenerationPreference, setSchemaGenerationPreference] = useState(null);

  const updateColumns = (values) => {
    const showConfirmed = !!(project?.project_files.find((item) => item.fileStatus === "12"))
    withConfirmation(
      'updateExcelColumns',
      async () => {
        const action = "updateExcelColumns";
        addAction(action);
        await post(actionTypes[action].api, {
          projectId: folderId,
          goals: showCustomInstruction ? goalRef.current?.resizableTextArea?.textArea?.value : null,
          columns: values.items.map((item) => ({
            fieldName: item.fieldName,
            sampleValue: item.sampleValue,
            dataType: item.dataType,
            instruction: showCustomInstruction ? item.instruction : undefined
          }))
        })
          .then(async (res) => {
            const newProject = await props.getProject()
            setProject(newProject)
          })
          .catch((err) => {
            setError(err);
          })
          .finally(() => {
            removeAction(action);
          })
      },
      showConfirmed
    )
  }

  if (!project?.excel_columns?.length && !schemaGenerationPreference) {
    return <Preference setSchemaGenerationPreference={setSchemaGenerationPreference} />
  }

  if (schemaGenerationPreference === 'chatbot-assisted') {
    return <Chatbot project={project} getProject={props.getProject} back={() => { setSchemaGenerationPreference(null) }} />
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
      <Form
        layout="vertical"
        onFinish={updateColumns}
        name="dynamic_form_complex"
        initialValues={{
          items: project?.excel_columns?.length
            ? project?.excel_columns
            : [{ "fieldName": null, "sampleValue": null }],
        }}
        form={form}
      >
        <Form.List name="items">
          {(fields, { add, remove }) => {
            return (
              <>
                <Card style={{ border: 0, backgroundColor: '#c3e3ff', padding: 4 }}>
                  <h2 style={{ fontFamily: 'Outfit' }}>Schema</h2>
                  <div style={{ display: 'flex', justifyContent: 'space-between', gap: 20, alignItems: 'end', flexDirection: window.innerWidth > 600 ? 'row' : 'column' }}>
                    <div>
                      Define your extraction output schema.
                      <a target='__blank' href="/example/extraction-schema" style={{ marginLeft: 5 }}>Examples</a>
                    </div>
                    <div style={{ display: 'flex', gap: 5 }}>
                      <Button
                        onClick={() => add()}
                      >
                        Add
                      </Button>
                      <Button htmlType="submit">
                        {!!(project?.project_files.find((item) => item.fileStatus === "12")) ? 'Save & reset' : 'Save'}
                      </Button>
                      <Tooltip
                        placement='bottom'
                        arrow={false}
                        color="white"
                        trigger="click"
                        title={
                          <div>
                            <Checkbox
                              checked={showCustomInstruction}
                              onChange={(val) => { setShowCustomInstruction(val.target.checked) }}
                            >
                              Advance analysis
                            </Checkbox>
                          </div>
                        }
                      >
                        <Button icon={<SettingOutlined />} />
                      </Tooltip>
                    </div>
                  </div>
                </Card>
                <div style={{ display: 'flex', gap: 10, marginTop: 10 }}>
                  <div style={{ display: 'flex', gap: 5, flexDirection: 'column', width: '100%' }}>
                    <div style={{ display: 'flex', gap: 5 }}>
                      <Card size='small' style={{ width: '100%', backgroundColor: '#f0f0f0' }}>
                        <div style={{ height: 22, padding: '0 7px' }}>
                          Field name
                        </div>
                      </Card>
                      <Card size='small' style={{ width: '100%', backgroundColor: '#f0f0f0' }}>
                        <div style={{ height: 22, padding: '0 7px' }}>
                          Sample value
                        </div>
                      </Card>
                      {
                        showCustomInstruction && (
                          <Card size='small' style={{ width: '100%',backgroundColor: '#f0f0f0' }}>
                            <div style={{ display: 'flex', gap: 10, justifyContent: 'space-between' }}>
                              <div style={{ height: 22, padding: '0 7px' }}>
                                Description / Instruction
                              </div>
                              <Tooltip title="To improve extraction accuracy, you can provide a detailed description of the field or define any specific rules or instructions that should be adhered to during the extraction process.">
                                ?
                              </Tooltip>
                            </div>
                          </Card>
                        )
                      }
                      <Card size='small' style={{ backgroundColor: '#f0f0f0' }}>
                        <div style={{ display: 'flex', gap: 5 }}>
                          <Button size='small' type='link' style={{ color: '#000000e0' }}>Action</Button>
                        </div>
                      </Card>
                    </div>
                    {
                      fields.map((item) => {
                        return (
                          <div key={item.key} style={{ display: 'flex', gap: 5 }}>
                            <Card size='small' style={{ width: '100%' }}>
                              <Form.Item noStyle name={[item.name, 'fieldName']}>
                                <Input
                                  size='small'
                                  placeholder="Field name"
                                  variant="borderless"
                                />
                              </Form.Item>
                            </Card>
                            <Card size='small' style={{ width: '100%' }}>
                              <Form.Item noStyle name={[item.name, 'sampleValue']}>
                                <Input
                                  size='small'
                                  placeholder="Sample value"
                                  variant="borderless"
                                />
                              </Form.Item>
                            </Card>
                            {
                              showCustomInstruction && (
                                <Card size='small' style={{ width: '100%' }}>
                                  <Form.Item noStyle name={[item.name, 'instruction']}>
                                    <TextArea
                                      size='small'
                                      placeholder="Field description or any custom instruction"
                                      variant="borderless"
                                    />
                                  </Form.Item>
                                </Card>
                              )
                            }
                            <Card size='small'>
                              <div style={{ display: 'flex', gap: 5 }}>
                                <Button size='small' type='link' onClick={() => { remove(item.name); }}>Delete</Button>
                              </div>
                            </Card>
                          </div>
                        )
                      })
                    }
                    <div style={{ display: 'flex', gap: 5 }}>
                      <Card size='small' style={{ width: '100%' }}>
                        <div style={{ height: 22, padding: '0 7px' }}>
                          File name
                        </div>
                      </Card>
                      <Card size='small' style={{ width: '100%' }}>
                        <div style={{ height: 22, padding: '0 7px' }}>
                          sample_file.pdf
                        </div>
                      </Card>
                      {
                        showCustomInstruction && (
                          <Card size='small' style={{ width: '100%' }}>
                            <div style={{ height: 22, padding: '0 7px' }}>
                              -
                            </div>
                          </Card>
                        )
                      }
                      <Card size='small'>
                        <div style={{ display: 'flex', gap: 5 }}>
                          <Button size='small' type='link' disabled>Delete</Button>
                        </div>
                      </Card>
                    </div>
                  </div>
                  {
                    showCustomInstruction &&
                    (
                      <Card style={{ minHeight: 'calc(100vh - 225px)', width: showGoal ? 400 : undefined }} size='small'>
                        <div style={{ display: 'flex', flexDirection: showGoal ? 'row' : 'column', gap: 10, alignItems: showGoal ? 'baseline' : 'center' }}>
                          <Button type='ghost' size='small' icon={showGoal ? <RightOutlined /> : <LeftOutlined />} onClick={() => { setShowGoal(!showGoal) }} />
                          <div style={{ transform: showGoal? undefined : "rotate(270deg)", fontWeight: 500, margin: showGoal ? '0 0 10px 0' : '20px 0 10px 0' }}>
                            {showGoal ? 'Goals / Custom Instructions' : 'Goals'}
                          </div>
                        </div>
                        {
                          showGoal && (
                            <TextArea
                              defaultValue={props.project?.goals}
                              ref={goalRef}
                              rows={15}
                              variant='borderless'
                              placeholder='Describe what do you want to achieve. '
                            />
                          )
                        }
                      </Card>
                    )
                  }
                </div>
              </>
            )
          }}
        </Form.List>
      </Form>
      <Button icon={<CommentOutlined />} style={{ position: 'absolute', right: 0, bottom: 0 }} type='primary' onClick={() => { setSchemaGenerationPreference('chatbot-assisted') }}>
        Chat
      </Button>
    </div>
  );
}

export default ExcelConfig;